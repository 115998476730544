import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError } from '@/store/setError';

const {
  postCreateAgent,
  postImportAgents,
  deleteAgent,
  putAgentRoles,
  putAgentOperators,
} = backOffice.agent;

export const scopes = {
  agent: 'agent',
  locations: 'locations',
  stations: 'stations',
  edit: 'edit',
  remove: 'remove',
  create: 'create',
};

const agents = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    setError,
    setEditAgent(state, agent) {
      state[scopes.edit] = agent;
    },
    clearEditAgent(state) {
      state[scopes.edit] = {};
    },
    setRemoveAgent(state, agent) {
      state[scopes.remove] = agent;
    },
    clearImportAgent(state) {
      state.data = [];
    },
  },
  actions: {
    putAgentRoles({ commit }, { agentUuid, data }) {
      return runAsyncFlow(commit, {
        request: putAgentRoles,
        params: [agentUuid, data],
      });
    },
    putAgentOperators({ commit }, { agentUuid, data }) {
      return runAsyncFlow(commit, {
        request: putAgentOperators,
        params: [agentUuid, data],
      });
    },
    deleteAgent({ commit }, uuid) {
      runAsyncFlow(commit, {
        request: deleteAgent,
        params: [uuid],
      });
    },
    postCreateAgent({ commit }, params) {
      runAsyncFlow(commit, {
        request: postCreateAgent,
        params: [params],
        scope: scopes.create,
      });
    },
    postImportAgents({ commit }, agentsList) {
      runAsyncFlow(commit, {
        request: postImportAgents,
        params: [agentsList],
      });
    },
  },
})));

export default agents;

import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { setError } from '@/store/setError';
import { portalHost } from '../../const/host.const';

const {
  getStationInfo,
  postStationGeofence,
  putStationGeofence,
  deleteStationGeofence,
} = carRental.station;

export const scopes = {
  fleet: 'fleet',
  geofence: 'geofence',
  deleteGeofence: 'deleteGeofence',
  stationInfo: 'stationInfo',
};

const stations = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      mutations: {
        setError,
      },
      actions: {
        getStationInfo({ rootState, commit }, stationId) {
          return runAsyncFlow(commit, {
            request: getStationInfo,
            params: [stationId, portalHost(rootState)],
            scope: scopes.stationInfo,
          });
        },
        postStationGeofence({ rootState, commit }, { stationId, geofence }) {
          return runAsyncFlow(commit, {
            request: postStationGeofence,
            params: [stationId, geofence, portalHost(rootState)],
            scope: scopes.geofence,
          });
        },
        putStationGeofence({ rootState, commit }, { stationId, geofenceName, geofence }) {
          return runAsyncFlow(commit, {
            request: putStationGeofence,
            params: [stationId, geofenceName, geofence, portalHost(rootState)],
            scope: scopes.geofence,
          });
        },
        deleteStationGeofence({ rootState, commit }, { stationId, geofenceName }) {
          return runAsyncFlow(commit, {
            request: deleteStationGeofence,
            params: [stationId, geofenceName, portalHost(rootState)],
            scope: scopes.geofence,
          });
        },
      },
    }
  )));

export default stations;

import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { setError } from '@/store/setError';
import { portalHost } from '../../../const/host.const';

const {
  getAlertTriggers,
  deleteAlertTrigger,
  putNewAlertTrigger,
} = carRental.alert;

export const scopes = {
  list: 'list',
  delete: 'delete',
};

const alertTriggerList = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      mutations: {
        updateQuery(state, query) {
          state.query = query;
        },
        setError,
      },
      actions: {
        getAlertTriggerList({ rootState, commit }, payload) {
          runAsyncFlow(commit, {
            request: getAlertTriggers,
            params: [payload, portalHost(rootState)],
            scope: scopes.list,
          });
        },
        putAlertTrigger({
          rootState, commit, dispatch, state,
        }, { data }) {
          runAsyncFlow(commit, {
            request: putNewAlertTrigger,
            params: [data, portalHost(rootState)],
          }).then(() => dispatch('getAlertTriggerList', state.query));
        },
        async deleteAlertTrigger({
          rootState, commit, dispatch, state,
        }, { triggerUuid }) {
          await runAsyncFlow(commit, {
            request: deleteAlertTrigger,
            params: [triggerUuid, portalHost(rootState)],
            scope: scopes.delete,
          }).then(() => dispatch('getAlertTriggerList', state.query));
        },
      },
    }
  )));

export default alertTriggerList;

import { createCustomStore } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import { setError } from '@/store/setError';

const companyHolidaysModule = createCustomStore(({ runAsyncFlow }) => ({
  mutations: {
    setError,
  },
  actions: {
    getPreBookingWorkingHours({ commit }, { companyUuid }) {
      return runAsyncFlow(commit, {
        request: external.preBooking.getPreBookingWorkingHours,
        params: [companyUuid],
      });
    },
    patchPreBookingWorkingHours({ commit }, { companyUuid, data }) {
      return runAsyncFlow(commit, {
        request: external.preBooking.patchPreBookingWorkingHours,
        params: [companyUuid, data],
      });
    },
  },
}));

export default companyHolidaysModule;

import { createCustomStore } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';

export const ReportsGroupModule = createCustomStore(({ runAsyncFlow }) => ({
  actions: {
    async getReportGroups({ commit }) {
      await runAsyncFlow(commit, {
        request: backOffice.reports.getReportGroups,
        params: [],
      });
    },
  },
}));

import FuelPriceLog from '../FuelPriceLog/store/FuelPriceModule';
import AutoInterventions from '../AutoInterventions/store/AutoInterventions';
import BookingRules from '../BookingRules/store/BookingRulesModule';

export default {
  namespaced: true,
  modules: {
    FuelPriceLog,
    AutoInterventions,
    BookingRules,
  },
};

import { backOffice } from '@emobg/web-api-client';
import { createCustomStore } from '@emobg/web-utils';

const { fleetInvoicing } = backOffice;

const salesOrderModel = createCustomStore(({ runAsyncFlow }) => ({
  actions: {
    getSalesOrder({ commit }, salesOrderUuid) {
      return runAsyncFlow(commit, {
        request: fleetInvoicing.getSalesOrder,
        params: [salesOrderUuid],
      });
    },
    postSalesOrderNewVehicles({ commit }, request) {
      return runAsyncFlow(commit, {
        request: fleetInvoicing.postSalesOrderNewVehicles,
        params: [request],
      });
    },
    postSalesOrderExistingVehicles({ commit }, request) {
      return runAsyncFlow(commit, {
        request: fleetInvoicing.postSalesOrderExistingVehicles,
        params: [request],
      });
    },
    postSalesOrderAddVehicle({ commit }, { salesOrderUuid, vehicleUuid }) {
      return runAsyncFlow(commit, {
        request: fleetInvoicing.postSalesOrderAddVehicle,
        params: [salesOrderUuid, vehicleUuid],
      });
    },
    patchSalesOrderVehicle({ commit }, { salesOrderUuid, vehicleUuid, data }) {
      return runAsyncFlow(commit, {
        request: fleetInvoicing.patchSalesOrderVehicle,
        params: [salesOrderUuid, vehicleUuid, data],
      });
    },
  },
}));

export default salesOrderModel;

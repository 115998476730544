import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';

const { getServiceTypes } = backOffice.fleetInvoicing;

export const SCOPES = {
  serviceTypes: 'serviceTypes',
};

export default createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  actions: {
    async getServiceTypes({ commit }, companyUuid) {
      await runAsyncFlow(commit, {
        request: getServiceTypes,
        params: [companyUuid],
        scope: SCOPES.serviceTypes,
      });
    },
  },
})));

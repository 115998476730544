import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError } from '@/store/setError';
export const scopes = {
  fleetSegment: 'fleetSegment',
};
const fleetSegmentModule = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  namespaced: true,
  mutations: {
    resetData(state) {
      state[scopes.fleetSegment].data = null;
      state[scopes.fleetSegment].error = null;
      state[scopes.fleetSegment].STATUS = { ...INITIAL_STATUS };
    },
    resetError(state) {
      state[scopes.fleetSegment].error = null;
      state[scopes.fleetSegment].STATUS = { ...INITIAL_STATUS };
    },
    setError(state, error) {
      const customError = error.value;
      if (!customError) {
        setError(error);
        return;
      }
      state[scopes.fleetSegment].error = customError;
      state[scopes.fleetSegment].STATUS.ERROR = true;
    },
    addFleetSegment(state, fleetSegment) {
      state.costs.data = fleetSegment;
    },
  },
  actions: {
    getFleetSegment({ commit }, { fleetSegmentUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.fleetSegment.getFleetSegment,
        params: [fleetSegmentUuid],
        scope: scopes.fleetSegment,
      });
    },
    postFleetSegment({ commit }, fleetSegment) {
      return runAsyncFlow(commit, {
        request: backOffice.fleetSegment.postFleetSegment,
        params: [fleetSegment],
        scope: scopes.fleetSegment,
      });
    },
    patchFleetSegment({ commit }, { uuid, fleetSegment }) {
      return runAsyncFlow(commit, {
        request: backOffice.fleetSegment.patchFleetSegment,
        params: [uuid, fleetSegment],
        scope: scopes.fleetSegment,
      });
    },
    deleteFleetSegment({ commit }, uuid) {
      return runAsyncFlow(commit, {
        request: backOffice.fleetSegment.deleteFleetSegment,
        params: [uuid],
        scope: scopes.fleetSegment,
      });
    },
  },
}));

export default fleetSegmentModule;

import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError } from '@/store/setError';

const scopes = {
  employees: 'employees',
};
const employeeModule = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  mutations: {
    setError,
  },
  actions: {
    putUserRoles({ commit }, { userUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.userCompany.putUserRoles,
        params: [userUuid, data],
        scope: scopes.employees,
      });
    },
    // TODO: replace new endpoints (with uuids) when BE finish this: https://europcarmobility.atlassian.net/browse/SBCCC-984
    postEmployee({ commit }, { companyId, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.employees.postEmployee,
        params: [companyId, data],
        scope: scopes.employees,
      });
    },
    deleteEmployee({ commit }, { companyId, employeeId }) {
      return runAsyncFlow(commit, {
        request: backOffice.employees.deleteEmployee,
        params: [companyId, employeeId],
        scope: scopes.employees,
      });
    },
    putEmployeeStatus({ commit }, { userId, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.employees.putEmployeeStatus,
        params: [userId, data],
        scope: scopes.employees,
      });
    },
    postImportEmployees({ commit }, { companyUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.employees.postImportEmployees,
        params: [companyUuid, data],
        scope: scopes.employees,
      });
    },
  },
}));

export default employeeModule;

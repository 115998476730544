import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { setError } from '@/store/setError';

const {
  getCaseEvent,
  postCaseEvent,
  putCaseEvent,
  postShareCaseEvent,
} = backOffice.caseEvent;
const { deleteCaseMedia } = backOffice.media;

export const scopes = {
  sharedAgents: 'sharedAgents',
  media: 'media',
};

const caseEventModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    setError,
  },
  actions: {
    async getCaseEvent({ commit }, caseEventUuid) {
      await runAsyncFlow(commit, {
        request: getCaseEvent,
        params: [caseEventUuid],
      });
    },
    async postCaseEvent({ commit }, { request }) {
      await runAsyncFlow(commit, {
        request: postCaseEvent,
        params: [request],
      });
    },
    async putCaseEvent({ commit }, { caseEventUuid, request }) {
      await runAsyncFlow(commit, {
        request: putCaseEvent,
        params: [caseEventUuid, request],
      });
    },
    async postShareCaseEvent({ commit }, { caseEventUuid, userUuids }) {
      await runAsyncFlow(commit, {
        request: postShareCaseEvent,
        params: [caseEventUuid, { userUuids }],
        scope: scopes.sharedAgents,
      });
    },
    async deleteCaseMedia({ commit }, { caseEventUuid, caseMediaUuid }) {
      await runAsyncFlow(commit, {
        request: deleteCaseMedia,
        params: [caseEventUuid, caseMediaUuid],
        scope: scopes.media,
      });
    },
  },
})));

export default caseEventModel;

export default {
  namespaced: true,
  state: {
    zoom: null,
    center: null,
  },
  mutations: {
    initialiseMapState(state) {
      state.zoom = null;
      state.center = {};
    },
    setZoom(state, payload) {
      state.zoom = payload;
    },
    setCenter(state, payload) {
      state.center = payload;
    },
  },
};

import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError } from '@/store/setError';

const scopes = {
  costAllocation: 'costAllocation',
};

const costAllocationsModule = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  mutations: {
    setError,
  },
  actions: {
    putConfigureCostAllocation({ commit }, { companyUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.companyCostAllocation.putConfigureCostAllocation,
        params: [companyUuid, payload],
        scope: scopes.costAllocation,
      });
    },
    deleteCostAllocation({ commit }, { companyUuid, uuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.companyCostAllocation.deleteCompanyCostAllocation,
        params: [companyUuid, uuid, 'v1'],
        scope: scopes.costAllocation,
      });
    },
    postCostAllocation({ commit }, { companyUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.companyCostAllocation.postCostAllocation,
        params: [companyUuid, payload],
        scope: scopes.costAllocation,
      });
    },
    putCostAllocation({ commit }, { companyUuid, costAllocationUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.companyCostAllocation.putUpdateConfigureCostAllocation,
        params: [companyUuid, costAllocationUuid, payload],
        scope: scopes.costAllocation,
      });
    },
    deleteCostAllocationCode({ commit }, { companyUuid, costAllocationUuid, costAllocationCodeUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.companyCostAllocation.deleteCompanyCostAllocationCodes,
        params: [companyUuid, costAllocationUuid, costAllocationCodeUuid],
        scope: scopes.costAllocation,
      });
    },
    postCostAllocationCode({ commit }, { companyUuid, costAllocationUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.companyCostAllocation.postCostAllocationCodes,
        params: [companyUuid, costAllocationUuid, payload],
        scope: scopes.costAllocation,
      });
    },
    putCostAllocationCode({ commit }, {
      companyUuid,
      costAllocationUuid,
      costAllocationCodeUuid,
      payload,
    }) {
      return runAsyncFlow(commit, {
        request: backOffice.companyCostAllocation.putCompanyCostAllocationCode,
        params: [companyUuid, costAllocationUuid, costAllocationCodeUuid, payload],
        scope: scopes.costAllocation,
      });
    },
  },
}));

export default costAllocationsModule;

import { backOffice } from '@emobg/web-api-client';
import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import each from 'lodash/each';
import findIndex from 'lodash/findIndex';

const {
  getBookingSetUser,
  getBookingSet,
  postBookingSet,
  putBookingSet,
  deleteBookingSet,
  putBookingSetVehicleSync,
  postBookingSetOccurrences,
  putBookingSetOccurrence,
  deleteBookingSetOccurrence,
  patchBookingSetCellsSave,
  patchBookingSetCellsBook,
} = backOffice.bookingSets;

export const scopes = {
  userProfiles: 'userProfiles',
  bookingSet: 'bookingSet',
  bookingSetVehicle: 'bookingSetVehicle',
  bookingSetOccurrence: 'bookingSetOccurrence',
  bookingSetCellsSave: 'bookingSetCellsSave',
  bookingSetCellsBook: 'bookingSetCellsBook',
};

const showNotificationError = (state, scope, commit) => {
  if (state[scope].STATUS.ERROR) {
    commit(DOMAINS_MODEL.app.messages.errors.throwError, state[scope].error, { root: true });
  }
};

const bookingSetModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    updateProcessedCells(state) {
      const { cells } = state[scopes.bookingSet].data;
      const updatedCells = state[scopes.bookingSetCellsBook].data;

      each(updatedCells, cell => {
        const index = findIndex(cells, { uuid: cell.uuid });
        if (index > -1) {
          cells.splice(index, 1, cell);
        }
      });
    },
    resetStore(state) {
      each(scopes, scope => {
        state[scope].data = [];
        state[scope].STATUS = { ...INITIAL_STATUS };
        state[scope].error = null;
      });
    },
  },
  actions: {
    async getBookingSetUser({ commit, state }, { userUuid, params }) {
      await runAsyncFlow(commit, {
        request: getBookingSetUser,
        params: [userUuid, params],
        scope: scopes.userProfiles,
      });

      showNotificationError(state, scopes.userProfiles, commit);
    },
    async getBookingSet({ commit, state }, { bookingSetUuid }) {
      await runAsyncFlow(commit, {
        request: getBookingSet,
        params: [bookingSetUuid],
        scope: scopes.bookingSet,
      });

      showNotificationError(state, scopes.bookingSet, commit);
    },
    async postBookingSet({ commit, state }, { request }) {
      await runAsyncFlow(commit, {
        request: postBookingSet,
        params: [request, 'v2'],
        scope: scopes.bookingSet,
      });

      showNotificationError(state, scopes.bookingSet, commit);
    },
    async putBookingSet({ commit, state }, { bookingSetUuid, request }) {
      await runAsyncFlow(commit, {
        request: putBookingSet,
        params: [bookingSetUuid, request, 'v2'],
        scope: scopes.bookingSet,
      });

      showNotificationError(state, scopes.bookingSet, commit);
    },
    async deleteBookingSet({ commit, state }, { bookingSetUuid, request }) {
      await runAsyncFlow(commit, {
        request: deleteBookingSet,
        params: [bookingSetUuid, request],
        scope: scopes.bookingSet,
      });

      showNotificationError(state, scopes.bookingSet, commit);
    },
    async putBookingSetVehicleSync({ commit, state }, { bookingSetUuid, request }) {
      await runAsyncFlow(commit, {
        request: putBookingSetVehicleSync,
        params: [bookingSetUuid, request],
        scope: scopes.bookingSetVehicle,
      });

      showNotificationError(state, scopes.bookingSetVehicle, commit);
    },
    async postBookingSetOccurrences({ commit, state }, { bookingSetUuid, request }) {
      await runAsyncFlow(commit, {
        request: postBookingSetOccurrences,
        params: [bookingSetUuid, request],
        scope: scopes.bookingSetOccurrence,
      });

      showNotificationError(state, scopes.bookingSetOccurrence, commit);
    },
    async putBookingSetOccurrence({ commit, state }, { bookingSetUuid, occurrenceUuid, request }) {
      await runAsyncFlow(commit, {
        request: putBookingSetOccurrence,
        params: [bookingSetUuid, occurrenceUuid, request],
        scope: scopes.bookingSetOccurrence,
      });

      showNotificationError(state, scopes.bookingSetOccurrence, commit);
    },
    async deleteBookingSetOccurrence({ commit, state }, { bookingSetUuid, occurrenceUuid, request }) {
      await runAsyncFlow(commit, {
        request: deleteBookingSetOccurrence,
        params: [bookingSetUuid, occurrenceUuid, request],
        scope: scopes.bookingSetOccurrence,
      });

      showNotificationError(state, scopes.bookingSetOccurrence, commit);
    },
    async patchBookingSetCellsSave({ commit, state }, { bookingSetUuid, vehicleUuid, request }) {
      await runAsyncFlow(commit, {
        request: patchBookingSetCellsSave,
        params: [bookingSetUuid, vehicleUuid, request],
        scope: scopes.bookingSetCellsSave,
      });

      showNotificationError(state, scopes.bookingSetCellsSave, commit);
    },
    async patchBookingSetCellsBook({ commit, state }, { bookingSetUuid, vehicleUuid, request }) {
      await runAsyncFlow(commit, {
        request: patchBookingSetCellsBook,
        params: [bookingSetUuid, vehicleUuid, request],
        scope: scopes.bookingSetCellsBook,
      });

      showNotificationError(state, scopes.bookingSetCellsBook, commit);
    },
  },
})));

export default bookingSetModel;

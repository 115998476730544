import { backOffice } from '@emobg/web-api-client';
import { createCustomStore } from '@emobg/web-utils';

const {
  addLongDistance,
  updateLongDistance,
  deleteLongDistance,
} = backOffice.availability;

export default createCustomStore(({ runAsyncFlow }) => ({
  actions: {
    async addLongDistance({ commit }, longDistance) {
      await runAsyncFlow(commit, {
        request: addLongDistance,
        params: [longDistance],
      });
    },
    async updateLongDistance({ commit }, { uuid, longDistance }) {
      await runAsyncFlow(commit, {
        request: updateLongDistance,
        params: [uuid, longDistance],
      });
    },
    async deleteLongDistance({ commit }, uuid) {
      await runAsyncFlow(commit, {
        request: deleteLongDistance,
        params: [uuid],
      });
    },
  },
}));

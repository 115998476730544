import Auth from '@domains/Auth/store/AuthModule';
import BusinessIntelligence from '@domains/BusinessIntelligence/store/BusinessIntelligenceModule';
import CRM from '@domains/CRM/store/CRMModule';
import Carrental from '@domains/Carrental/store/CarrentalModule';
import Fleet from '@domains/Fleet/store/FleetModule';
import Carsharing from '@domains/Carsharing/store/CarsharingModule';
import CasesServices from '@domains/CasesServices/store/CasesServicesModule';
import FleetInvoicing from '@domains/FleetInvoicing/store/FleetInvoicingModule';
import Invoicing from '@domains/Invoicing/store/InvoicingModule';
import Pricing from '@domains/Pricing/store/PricingModule';
import Settings from '@domains/Settings/store/SettingsModule';
import Promotions from '@domains/Promotions/store/PromotionsModule';
import Dashboard from '@domains/Dashboard/store/DashboardModule';
import Shared from '@domains/Shared/store/SharedModule';

export default {
  namespaced: true,
  modules: {
    Auth,
    BusinessIntelligence,
    CRM,
    CasesServices,
    Carrental,
    Carsharing,
    Dashboard,
    Fleet,
    FleetInvoicing,
    Invoicing,
    Pricing,
    Promotions,
    Settings,
    Shared,
  },
};

import { createCustomStore } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError } from '@/store/setError';

const whitelistedDomainsModule = createCustomStore(({ runAsyncFlow }) => ({
  mutations: {
    setError,
  },
  actions: {
    getWhitelistedDomains({ commit }, { companyUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.whitelistedDomains.getWhitelistedDomains,
        params: [companyUuid],
      });
    },
    postWhitelistedDomains({ commit }, { companyUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.whitelistedDomains.postWhitelistedDomains,
        params: [companyUuid, data],
      });
    },
    deleteWhitelistedDomains({ commit }, { companyUuid, whitelistedDomainUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.whitelistedDomains.deleteWhitelistedDomains,
        params: [companyUuid, whitelistedDomainUuid],
      });
    },
  },
}));

export default whitelistedDomainsModule;

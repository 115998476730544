import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { clearErrors, toastError } from '@/utils';

export const BOOKING_RULES_SCOPES = {
  editForm: 'editForm',
};

const bookingRulesModule = createCustomStore(({ runAsyncFlow }) => withScopes(BOOKING_RULES_SCOPES, {
  mutations: {
    setError(state, payload) {
      toastError.bind(this)(state, payload, []);
    },
    clearErrors,
  },
  actions: {
    getOperatorBookingRules({ commit }, { operatorUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.bookingRules.getBookingRules,
        params: [operatorUuid],
      });
    },
    patchOperatorBookingRule({ commit }, { operatorUuid, valueId, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.bookingRules.patchBookingRule,
        params: [operatorUuid, valueId, data],
        scope: BOOKING_RULES_SCOPES.editForm,
      });
    },
  },
}));

export default bookingRulesModule;

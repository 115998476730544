import { backOffice } from '@emobg/web-api-client';
import { toastError } from '@/utils';
import { createCustomStore } from '@emobg/web-utils';
const {
  addVehicleLocation,
  updateVehicleLocation,
  deleteVehicleLocation,
} = backOffice.vehicleLocations;

export default createCustomStore(({ runAsyncFlow }) => ({
  actions: {
    async addVehicleLocation({ commit }, vehicleLocation) {
      await runAsyncFlow(commit, {
        request: addVehicleLocation,
        params: [vehicleLocation],
      });
    },
    async updateVehicleLocation({ commit }, { uuid, vehicleLocation }) {
      await runAsyncFlow(commit, {
        request: updateVehicleLocation,
        params: [uuid, vehicleLocation],
      });
    },
    async deleteVehicleLocation({ commit }, uuid) {
      await runAsyncFlow(commit, {
        request: deleteVehicleLocation,
        params: [uuid],
      });
    },
  },
  mutations: {
    setError(state, payload) {
      toastError.bind(this)(
        state,
        payload,
        [null],
      );
    },
  },
}));

import { backOffice } from '@emobg/web-api-client';
import { createCustomStore } from '@emobg/web-utils';
import { clearErrors } from '@/utils';

const {
  postCity,
  postEditCity,
} = backOffice.cities;

const cities = createCustomStore(({ runAsyncFlow }) => ({
  mutation: {
    clearErrors,
  },
  actions: {
    async postEditCity({ commit }, { cityId, data }) {
      await runAsyncFlow(commit, {
        request: postEditCity,
        params: [cityId, data],
      });
    },
    async postCity({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postCity,
        params: [data],
      });
    },
  },
}));

export default cities;

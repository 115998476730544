import Agents from '../Agents/store/AgentsModule';
import Alerts from '../Alerts/Details/store/AlertDetailsModule';
import AlertsTrigger from '../AlertsTrigger/store/AlertsTriggerModule';

import Devices from '../Devices/store/DevicesModule';

import Stations from '../Stations/store/StationsModule';

import FleetCheck from '../FleetCheck/store/FleetCheckModule';

import Trip from '../Trip/store/TripModule';
import Vehicle from '../Vehicles/Summary/store/VehicleSummaryModule';

export default {
  namespaced: true,
  modules: {
    Agents,
    Alerts,
    Devices,
    AlertsTrigger,
    Stations,
    FleetCheck,
    Trip,
    Vehicle,
  },
};

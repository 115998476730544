import { backOffice } from '@emobg/web-api-client';
import { createCustomStore } from '@emobg/web-utils';

const { getAuditData } = backOffice.audit;

const audit = createCustomStore(({ runAsyncFlow }) => ({
  actions: {
    async getAuditData({ commit }, query) {
      await runAsyncFlow(commit, {
        request: getAuditData,
        params: [query],
      });
    },
  },
}));

export default audit;

import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import get from 'lodash/get';
import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { setError } from '@/store/setError';
import { parseCost } from '../utils';
const {
  postSettlement,
  patchSettlementRefund,
  postSpecialCharge,
  patchSpecialCharge,
  getSettlementsByBookingUuid,
} = backOffice.airplus;
const scopes = {
  costs: 'costs',
  refund: 'refund',
  specialCharge: 'specialCharge',
  settlements: 'settlements',
};

const additionalCostsModule = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  namespaced: true,
  getters: {
    settlements: state => get(state, 'settlements.data.data'),
  },
  mutations: {
    setError,
    addCost(state, cost) {
      state.costs.data = [...state.costs.data, cost];
    },
    resetCosts(state) {
      state.costs.data = [];
    },
    deleteCost(state, cost) {
      state.costs.data = remove(state.costs.data, currentCost => !isEqual(currentCost, cost));
    },
  },
  actions: {
    postAdditionalCosts({ state, commit }, { bookingUuid, userUuid }) {
      const settlementLines = map(get(state, 'costs.data'), cost => parseCost(cost));
      return runAsyncFlow(commit, {
        request: postSettlement,
        params: [bookingUuid, { settlementLines, userUuid }],
        scope: scopes.costs,
      });
    },
    patchAdditionalCosts({ commit }, { bookingUuid, settlementUuid, userUuid }) {
      return runAsyncFlow(commit, {
        request: patchSettlementRefund,
        params: [bookingUuid, settlementUuid, { userUuid }],
        scope: scopes.refund,
      });
    },
    postSpecialCharge({ commit }, { externalServiceUuid, settlementUuid, specialCharge }) {
      return runAsyncFlow(commit, {
        request: postSpecialCharge,
        params: [externalServiceUuid, settlementUuid, specialCharge],
        scope: scopes.specialCharge,
      });
    },
    patchSpecialCharge({ commit }, { externalServiceUuid, settlementUuid, specialCharge }) {
      return runAsyncFlow(commit, {
        request: patchSpecialCharge,
        params: [externalServiceUuid, settlementUuid, specialCharge],
        scope: scopes.specialCharge,
      });
    },
    getSettlementsByBookingUuid({ commit }, { bookingUuid, userUuid }) {
      return runAsyncFlow(commit, {
        request: getSettlementsByBookingUuid,
        params: [bookingUuid, userUuid],
        scope: scopes.settlements,
      });
    },
  },
}));

export default additionalCostsModule;

import { backOffice } from '@emobg/web-api-client';
import { INITIAL_STATUS, createCustomStore } from '@emobg/web-utils';

const { getCities } = backOffice.cities;

const citiesModule = createCustomStore(({ runAsyncFlow }) => ({
  mutations: {
    resetCityData(state) {
      state.STATUS = { ...INITIAL_STATUS };
      state.data = [];
    },
  },
  actions: {
    async getCities({ commit }, params) {
      await runAsyncFlow(commit, {
        request: getCities,
        params: [params],
      });
    },
  },
}));

export default citiesModule;

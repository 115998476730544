import { backOffice } from '@emobg/web-api-client';
import { createCustomStore } from '@emobg/web-utils';

const { fleetInvoicing } = backOffice;

export const servicesFeesStoreConfig = ({ runAsyncFlow }) => ({
  actions: {
    saveServiceFee({ commit }, { serviceFeeUuid, request }) {
      const saveFeeCall = serviceFeeUuid ? {
        request: fleetInvoicing.putServiceFee,
        params: [serviceFeeUuid, request],
      } : {
        request: fleetInvoicing.postServiceFee,
        params: [request],
      };

      runAsyncFlow(commit, saveFeeCall);
    },
  },
});

const serviceFeesModel = createCustomStore(servicesFeesStoreConfig);

export default serviceFeesModel;

import Tariffs from '../Tariffs/store/TariffsModule';
import Services from '../Services/store/ServicesModule';

export default {
  namespaced: true,
  modules: {
    Tariffs,
    Services,
  },
};

import Invoices from '../Invoices/store/InvoiceModule';
import Payments from '../Payments/store/PaymentsModule';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
  },
  modules: {
    Invoices,
    Payments,
  },
};

import {
  INITIAL_STATUS,
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { MFA_ACTIONS } from '../const/index';
const {
  postMfaCode,
  postResendMfaCode,
} = backOffice.multiFactorAuth;

export const scopes = {
  actionFeedback: 'actionFeedback',
};

const mfaModule = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    setActionFeedback(state, feedback) {
      state[scopes.actionFeedback].data = feedback;
    },
    resetStore(state) {
      state[scopes.actionFeedback].data = null;
      state.data = [];
      state.error = null;
      state.STATUS = { ...INITIAL_STATUS };
    },
  },
  actions: {
    async postCode({ commit }, code) {
      commit('resetStore');
      commit('setActionFeedback', MFA_ACTIONS.postCode);
      await runAsyncFlow(commit, {
        request: postMfaCode,
        params: [{ code }],
      });
    },
    async resendCode({ commit }, { actionFeedback = MFA_ACTIONS.resendCode }) {
      commit('resetStore');
      commit('setActionFeedback', actionFeedback);
      await runAsyncFlow(commit, {
        request: postResendMfaCode,
      });
    },
  },
})));

export default mfaModule;

import { createCustomStore } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError } from '@/store/setError';

const employeeModule = createCustomStore(({ runAsyncFlow }) => ({
  mutations: {
    setError,
  },
  actions: {
    getCircle({ commit }, { circleUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.userCircles.getCircle,
        params: [circleUuid],
      });
    },
    postCircle({ commit }, { data }) {
      return runAsyncFlow(commit, {
        request: backOffice.userCircles.postCircle,
        params: [data],
      });
    },
    putCircle({ commit }, { circleUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.userCircles.putCircle,
        params: [circleUuid, data],
      });
    },
    deleteCircle({ commit }, { circleUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.userCircles.deleteCircle,
        params: [circleUuid],
      });
    },
    putAssignEmployees({ commit }, { circleUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.userCircles.putAssignEmployees,
        params: [circleUuid, data],
      });
    },
    putAssignManagers({ commit }, { circleUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.userCircles.putAssignManagers,
        params: [circleUuid, data],
      });
    },
    postAssignFleetSegments({ commit }, { circleUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.userCircles.postAssignFleetSegments,
        params: [circleUuid, data],
      });
    },
  },
}));

export default employeeModule;

import { backOffice } from '@emobg/web-api-client';
import { INITIAL_STATUS, createCustomStore } from '@emobg/web-utils';

const { getUnavailabilityList } = backOffice.availability;

const unavailabilityListModule = createCustomStore(({ runAsyncFlow }) => ({
  mutations: {
    resetStore(state) {
      state.STATUS = { ...INITIAL_STATUS };
      state.data = [];
      state.error = [];
    },
  },
  actions: {
    async getUnavailabilityList({ commit }, params) {
      await runAsyncFlow(commit, {
        request: getUnavailabilityList,
        params: [params],
      });
    },
  },
}));

export default unavailabilityListModule;

import Contracts from '../Contracts/store/ContractsModule';
import SalesOrders from '../SalesOrders/store/SalesOrdersModule';
import ServiceFees from '../ServiceFees/store/ServiceFeesModule';
import ServiceTypes from '../ServiceFees/store/ServiceTypesModule';
import Invoices from '../Invoices/store/InvoicesModule';
import InvoicePlan from '../InvoicePlan/store/InvoicePlanModule';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    Contracts,
    Invoices,
    InvoicePlan,
    SalesOrders,
    ServiceFees,
    ServiceTypes,
  },
};

import each from 'lodash/each';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import { backOffice } from '@emobg/web-api-client';
import {
  camelCaseKeys,
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';

const fleetInvoicingApi = backOffice.fleetInvoicing;

export const SCOPES = Object.freeze({
  INVOICE_PLANS: 'invoicePlans',
});

const initialContract = {
  uuid: null,
  type: null,
  startDate: null,
  description: null,
  attachment: null,
  companyUuid: null,
  contractDocuments: [],
};

const contractsModel = createCustomStore(() => withScopes(SCOPES, {
  namespace: true,
  state: {
    data: {
      contract: { ...initialContract },
    },
  },
  mutations: {
    setContract(state, newContract) {
      const contract = { ...camelCaseKeys(newContract) };

      state.data = { ...state.data, contract };
    },
    setError(state, value) {
      state.error = value;
    },
    addContractDocument(state, documents) {
      const uniqueDocs = uniq(documents);

      set(state, 'data.contract.contractDocuments', uniqueDocs);
    },
  },
  actions: {
    initContract({ commit, dispatch }, contractUuid) {
      commit('setContract', initialContract);

      if (contractUuid) {
        dispatch('getContract', contractUuid);
      }
    },
    async getContract({ commit }, contractUuid) {
      try {
        commit('setStatus', 'LOADING');
        const contract = await fleetInvoicingApi.getContract(contractUuid);

        commit('setContract', contract.data);
        commit('setStatus', 'LOADED');
      } catch (error) {
        commit('setStatus', 'ERROR');
        throw error;
      }
    },
    // TODO: BL-243 Review if this one can be migrated to runAsyncFlow
    async postContract({ commit }, contract) {
      const contractResponse = await fleetInvoicingApi.postContract(contract);

      commit('setContract', contractResponse.data);
    },

    async updateContract({ commit }, { contractUuid, contract }) {
      const contractResponse = await fleetInvoicingApi.putContract(contractUuid, contract);

      commit('setContract', contractResponse.data);
    },

    async uploadFiles({ commit }, { files, contractUuid }) {
      const willUploadDocuments = [];

      each(files, fileToUpload => {
        const willUploadFile = fleetInvoicingApi.postContractFile(
          contractUuid,
          fileToUpload,
        );
        willUploadDocuments.push(willUploadFile);
      });

      const uploadedDocuments = await Promise.all(willUploadDocuments);

      each(uploadedDocuments, uploadedDocument => {
        const camelizedAnswer = camelCaseKeys(uploadedDocument);
        commit(
          'addContractDocument',
          camelizedAnswer.data.contractDocuments.slice(),
        );
      });
    },

    async saveContract(
      { dispatch, state, commit },
      { contractUuid, contract, binaryFiles },
    ) {
      try {
        commit('setStatus', 'LOADING');

        const willSaveContract = contractUuid
          ? dispatch('updateContract', { contractUuid, contract })
          : dispatch('postContract', contract);

        const contractAndFiles = await Promise.all([
          willSaveContract,
          binaryFiles,
        ]);

        const files = contractAndFiles[1];
        await dispatch('uploadFiles', {
          contractUuid: state.data.contract.uuid,
          files,
        });

        commit('setStatus', 'LOADED');
      } catch (error) {
        commit('setStatus', 'ERROR');
        throw error;
      }
    },
  },
}));

export default contractsModel;

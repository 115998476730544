import { backOffice } from '@emobg/web-api-client';
import { createCustomStore } from '@emobg/web-utils';

export const ACTIONS = {
  getReport: 'getReport',
  postReport: 'postReport',
  patchReport: 'patchReport',
  deleteReport: 'deleteReport',
};

export const ReportsModule = createCustomStore(({ runAsyncFlow }) => (
  {
    actions: {
      async [ACTIONS.getReport]({ commit }, uuid) {
        await runAsyncFlow(commit, {
          request: backOffice.reports.getReport,
          params: [uuid],
        });
      },
      async [ACTIONS.postReport]({ commit }, report) {
        await runAsyncFlow(commit, {
          request: backOffice.reports.postReport,
          params: [report],
        });
      },
      async [ACTIONS.patchReport]({ commit }, { uuid, report }) {
        await runAsyncFlow(commit, {
          request: backOffice.reports.patchReport,
          params: [uuid, report],
        });
      },
      async [ACTIONS.deleteReport]({ commit }, uuid) {
        await runAsyncFlow(commit, {
          request: backOffice.reports.deleteReport,
          params: [uuid],
        });
      },
    },
  }
));

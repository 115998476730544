import map from 'lodash/map';
import find from 'lodash/find';
import reduce from 'lodash/reduce';

import { toastError } from '@/utils';
import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { portalHost } from '../../const/host.const';

const {
  getFleetCheckList,
  getFleetCheck,
  getReasons,
  postFleetCheck,
  patchFleetCheck,
} = carRental.fleetCheck;

const { getStationsList } = carRental.station;

export const scopes = {
  fleetChecks: 'fleetChecks',
  ongoingFleetCheck: 'ongoingFleetCheck',
  fleetCheck: 'fleetCheck',
  fleetCheckDetails: 'fleetCheckDetails',
  stations: 'stations',
  reasons: 'reasons',
};

const fleetCheck = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      state: {
        selectedStationsId: [],
        exceptionVehicles: [],
      },
      mutations: {
        setSelectedStationsId(state, stations) {
          state.selectedStationsId = stations;
        },
        addExceptionVehicle(state, exceptionVehicle) {
          const vehicle = find(state.exceptionVehicles, { vin: exceptionVehicle.vin });
          if (vehicle) {
            vehicle.reason = exceptionVehicle.reason;
          } else {
            state.exceptionVehicles.push(exceptionVehicle);
          }
        },
        clearNewFleetCheckData(state) {
          state.ongoingFleetCheck.data = [];
          state.fleetCheck.data = [];
          state.selectedStationsId = [];
          state.exceptionVehicles = [];
        },
        setError(state, payload) {
          toastError.bind(this)(state, payload, [
            scopes.fleetChecks,
            scopes.ongoingFleetCheck,
            scopes.fleetCheck,
            scopes.stations,
            scopes.reasons,
          ]);
        },
      },
      actions: {
        getFleetCheckList({ rootState, commit }, { query = {} }) {
          runAsyncFlow(commit, {
            request: getFleetCheckList,
            params: [query, portalHost(rootState)],
            scope: scopes.fleetChecks,
          });
        },
        async getFleetCheck({ rootState, commit }, fleetCheckId) {
          return runAsyncFlow(commit, {
            request: getFleetCheck,
            params: [fleetCheckId, portalHost(rootState)],
            scope: scopes.fleetCheckDetails,
          });
        },
        getStationsList({ rootState, commit }, { query = {} }) {
          runAsyncFlow(commit, {
            request: getStationsList,
            params: [query, portalHost(rootState)],
            scope: scopes.stations,
          });
        },
        getReasons({ rootState, commit }) {
          runAsyncFlow(commit, {
            request: getReasons,
            params: [portalHost(rootState)],
            scope: scopes.reasons,
          });
        },
        async postFleetCheck({ rootState, commit }, fleetCheckData) {
          return runAsyncFlow(commit, {
            request: postFleetCheck,
            params: [fleetCheckData, portalHost(rootState)],
            scope: scopes.ongoingFleetCheck,
          });
        },
        async patchFleetCheck({ rootState, commit }, { fleetCheckId, updateFleetCheckData }) {
          return runAsyncFlow(commit, {
            request: patchFleetCheck,
            params: [fleetCheckId, updateFleetCheckData, portalHost(rootState)],
            scope: scopes.fleetCheck,
          });
        },
      },
      getters: {
        getStations(state) {
          return map(state[scopes.stations].data, ({ greenwayId, greenwayName, geofences }) => {
            const geofencesCount = (geofences || []).length;
            return {
              value: greenwayId,
              label: `${greenwayId} ${greenwayName} (${geofencesCount} ${geofencesCount === 1 ? 'geofence' : 'geofences'})`,
            };
          });
        },
        getExceptionReasons(state) {
          return reduce(state[scopes.reasons].data, (result, { header, reasons }) => {
            result.push({
              value: header,
              label: header,
              isHeader: true,
            });
            const sectionReasonsArray = map(reasons, reason => ({
              value: reason,
              label: reason,
            }));
            return result.concat(sectionReasonsArray);
          }, []);
        },
      },
    }
  )));

export default fleetCheck;

import NewAlertMap from '../Trigger/store/NewAlertMapModule';
import NewAlertTrigger from '../Trigger/store/NewAlertTriggerModule';
import AlertTriggerList from '../List/store/AlertTriggerListModule';

export default {
  namespaced: true,
  modules: {
    AlertTriggerList,
    NewAlertTrigger,
    NewAlertMap,
  },
};

import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';

const { conciliationReports } = backOffice;

export const SCOPES = {
  conciliationReports: 'conciliationReports',
  conciliationReportMedia: 'conciliationReportMedia',
};

export default createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  actions: {
    async getConciliationReports({ commit }, operatorUuid) {
      await runAsyncFlow(commit, {
        request: conciliationReports.getConciliationReports,
        params: [operatorUuid],
        scope: SCOPES.conciliationReports,
      });
    },
    async getConciliationReport({ commit }, mediaUuid) {
      await runAsyncFlow(commit, {
        request: conciliationReports.getConciliationReportMedia,
        params: [mediaUuid],
        scope: SCOPES.conciliationReportMedia,
      });
    },
  },
})));

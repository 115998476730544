import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { setError } from '@/store/setError';

const {
  getTriggerAndInterventionTypes,
  getAutoInterventionsSettings,
  putAutoInterventionsSettings,
} = backOffice.csOperator;

export const scopes = {
  configuration: 'configuration',
  settings: 'settings',
};

const autoInterventions = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    setError,
    resetData(state) {
      state[scopes.configuration].data = null;
      state[scopes.settings].data = {
        interventionTypes: null,
        trigger: null,
      };
    },
  },
  actions: {
    async getTriggerAndInterventionTypes({ commit }) {
      await runAsyncFlow(commit, {
        request: getTriggerAndInterventionTypes,
        scope: scopes.settings,
      });
    },
    async getAutoInterventionsSettings({ commit }, csOperatorUuid) {
      commit('resetData');
      await runAsyncFlow(commit, {
        request: getAutoInterventionsSettings,
        params: [csOperatorUuid],
        scope: scopes.configuration,
      });
    },
    async putAutoInterventionsSettings({ commit }, { operatorUuid, data }) {
      await runAsyncFlow(commit, {
        request: putAutoInterventionsSettings,
        params: [operatorUuid, data],
        scope: scopes.configuration,
      });
    },
  },
})));

export default autoInterventions;

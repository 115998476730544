import LongDistanceDetail from '../components/LongDistance/components/Form/store/LongDistanceDetailModule';
import LongDistanceList from '../components/LongDistance/store/LongDistanceListModule';
import UnavailabilityDetail from '../components/Unavailabilities/components/Form/store/UnavailabilityDetailModule';
import UnavailabilityList from '../components/Unavailabilities/store/UnavailabilityListModule';
import VehicleAllocationDetail from '../components/VehicleAllocation/VehicleAllocationForm/store/VehicleAllocationDetailModule';
import VehicleAllocationsList from '../components/VehicleAllocation/store/VehicleAllocationsListModule';

export default {
  namespaced: true,
  modules: {
    LongDistanceDetail,
    LongDistanceList,
    UnavailabilityDetail,
    UnavailabilityList,
    VehicleAllocationDetail,
    VehicleAllocationsList,
  },
};

import Payouts from '../Payouts/store/PayoutsModule';
import Roles from '../Roles/store/RolesModule';
import FleetSegment from '../Companies/store/FleetSegmentModule';
import Company from '../Companies/store/CompanyModule';
import CostAllocations from '../Companies/store/CostAllocationsModule';
import Employees from '../Companies/store/EmployeesModule';
import CompanyHolidays from '../Companies/store/CompanyHolidaysModule';
import Agents from '../Agents/store/AgentsModule';
import Users from '../Users/store/UsersModule';
import Circles from '../Companies/store/CirclesModule';
import WhitelistedDomains from '../Companies/store/WhitelistedDomainsModule';
import LegalDocuments from '../CsOperators/LegalDocuments/store/LegalDocumentsModule';
import CsOperatorSettings from '../CsOperators/Settings/store/CsOperatorSettingsModule';
import IdentityTypes from '../IdentityTypes/store/IdentityTypesModule';

export default {
  namespaced: true,
  modules: {
    Agents,
    Company,
    FleetSegment,
    Payouts,
    Roles,
    CostAllocations,
    CompanyHolidays,
    Employees,
    Users,
    Circles,
    WhitelistedDomains,
    LegalDocuments,
    CsOperatorSettings,
    IdentityTypes,
  },
};

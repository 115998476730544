import ChargePostModels from '../ChargePostModels/store/ChargePostModelsModule';
import ChargePosts from '../ChargePosts/store/ChargePostsModule';
import FuelCards from '../Hardware/FuelCards/store/FuelCardsModule';
import Devices from '../Hardware/Devices/store/DevicesModule';
import Locations from '../Locations/store/LocationsModule';
import Sims from '../Hardware/Sims/store/SimsModule';
import VehicleCategories from '../VehicleSettings/Categories/store/VehicleCategoriesModule';
import VehicleBrands from '../VehicleSettings/Brands/store/VehicleBrandsModule';
import VehicleEquipment from '../VehicleSettings/Equipment/store/VehicleEquipmentModule';
import VehicleModels from '../VehicleModels/store/VehicleModelsModule';
import Cities from '../Cities/store/CitiesModule';

export default {
  namespaced: true,
  modules: {
    ChargePosts,
    ChargePostModels,
    Devices,
    FuelCards,
    Locations,
    Sims,
    VehicleBrands,
    VehicleCategories,
    VehicleEquipment,
    VehicleModels,
    Cities,
  },
};

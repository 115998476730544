import each from 'lodash/each';
import omit from 'lodash/omit';
import { backOffice } from '@emobg/web-api-client';
import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { clearErrors, toastError } from '@/utils';

const {
  deleteLegacyTariff,
  deleteTariff,
  getTariff,
  getTariffsList,
  getTariffServices,
  getCityDefaultTariff,
  getCityGuestTariff,
  getInternalNameAvailability,
  postTariff,
  putTariff,
} = backOffice.tariffs;
const {
  getModifiersByTariff,
  postModifier,
  patchDeactivateModifier,
} = backOffice.pricingModifiers;
const { getVehicleCategories } = backOffice.categories;
const { getCities } = backOffice.cities;
const { getOperatorsConfig } = backOffice.userCompany;

export const SCOPES = {
  tariff: 'tariff',
  legacyTariff: 'legacyTariff',
  saveTariff: 'saveTariff',
  checkInternalName: 'checkInternalName',
  hasDefaultTariff: 'hasDefaultTariff',
  hasGuestTariff: 'hasGuestTariff',
  servicesLines: 'servicesLines',
  vehicleCategories: 'vehicleCategories',
  cities: 'cities',
  tariffsList: 'tariffsList',
  deleteTariff: 'deleteTariff',
  operatorConfig: 'operatorConfig',
  modifiersList: 'modifiersList',
  createModifier: 'createModifier',
  deactivateModifier: 'deactivateModifier',
};

const tariffsModel = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  actions: {
    async deleteLegacyTariff({ commit }, tariffId) {
      await runAsyncFlow(commit, {
        request: deleteLegacyTariff,
        scope: SCOPES.deleteTariff,
        params: [tariffId],
      });
    },
    async deleteTariff({ commit }, uuid) {
      await runAsyncFlow(commit, {
        request: deleteTariff,
        scope: SCOPES.deleteTariff,
        params: [uuid],
      });
    },
    async getTariffs({ commit }, operatorUuid) {
      await runAsyncFlow(commit, {
        request: getTariffsList,
        scope: SCOPES.tariffsList,
        params: [operatorUuid],
      });
    },
    async getCities({ commit }, csOperatorUuid) {
      await runAsyncFlow(commit, {
        request: getCities,
        params: [{ csOperatorUuid }],
        scope: SCOPES.cities,
      });
    },
    async getTariff({ commit }, tariffUuid) {
      await runAsyncFlow(commit, {
        request: getTariff,
        params: [tariffUuid, 'v2'],
        scope: SCOPES.tariff,
      });
    },
    async getCityDefaultTariff({ commit }, { cityUuid, query }) {
      await runAsyncFlow(commit, {
        request: getCityDefaultTariff,
        params: [cityUuid, query, 'v2'],
        scope: SCOPES.hasDefaultTariff,
      });
    },
    async getCityGuestTariff({ commit }, { cityUuid, query }) {
      await runAsyncFlow(commit, {
        request: getCityGuestTariff,
        params: [cityUuid, query, 'v2'],
        scope: SCOPES.hasGuestTariff,
      });
    },
    async getInternalNameAvailability({ commit }, data) {
      await runAsyncFlow(commit, {
        request: getInternalNameAvailability,
        params: [data],
        scope: SCOPES.checkInternalName,
      });
    },
    async postTariff({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postTariff,
        params: [data, 'v2'],
        scope: SCOPES.saveTariff,
      });
    },
    async putTariff({ commit }, { tariffUuid, data }) {
      await runAsyncFlow(commit, {
        request: putTariff,
        params: [data, tariffUuid, 'v2'],
        scope: SCOPES.saveTariff,
      });
    },
    async getVehicleCategories({ commit }, operatorUuid) {
      await runAsyncFlow(commit, {
        request: getVehicleCategories,
        scope: SCOPES.vehicleCategories,
        params: [{ operator_uuid: operatorUuid }],
      });
    },
    async getTariffServices({ commit }, operatorUuid) {
      await runAsyncFlow(commit, {
        request: getTariffServices,
        scope: SCOPES.servicesLines,
        params: [{ operator_uuid: operatorUuid }],
      });
    },
    async getOperatorConfig({ commit }, operatorUuid) {
      await runAsyncFlow(commit, {
        request: getOperatorsConfig,
        params: [operatorUuid],
        scope: SCOPES.operatorConfig,
      });
    },
    async getLegacyTariff({ commit }, tariffUuid) {
      await runAsyncFlow(commit, {
        request: getTariff,
        params: [tariffUuid, 'v2', { migrated: 0 }],
        scope: SCOPES.legacyTariff,
      });
    },
    async getModifiersByTariff({ commit }, tariffUuid) {
      await runAsyncFlow(commit, {
        request: getModifiersByTariff,
        params: [tariffUuid],
        scope: SCOPES.modifiersList,
      });
    },
    async postModifier({ commit }, { tariffUuid, data }) {
      await runAsyncFlow(commit, {
        request: postModifier,
        params: [tariffUuid, data],
        scope: SCOPES.createModifier,
      });
    },
    async patchDeactivateModifier({ commit }, { tariffUuid, modifierUuid }) {
      await runAsyncFlow(commit, {
        request: patchDeactivateModifier,
        params: [tariffUuid, modifierUuid],
        scope: SCOPES.deactivateModifier,
      });
    },
  },
  mutations: {
    clearErrors,
    resetStore(state) {
      const scopesToReset = omit(SCOPES, ['tariffsList']);
      each(scopesToReset, (_value, key) => {
        state[key].data = [];
        state[key].error = null;
        state[key].STATUS = { ...INITIAL_STATUS };
      });
    },
    setError(state, payload) {
      toastError.bind(this)(
        state,
        payload,
        [SCOPES.tariff, SCOPES.saveTariff, SCOPES.legacyTariff, SCOPES.createModifier, SCOPES.deactivateModifier],
      );
    },
  },
})));

export default tariffsModel;

import { createCustomStore } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';

const {
  addUnavailability,
  updateUnavailability,
  deleteUnavailability,
} = backOffice.availability;

export default createCustomStore(({ runAsyncFlow }) => ({
  actions: {
    async addUnavailability({ commit }, unavailability) {
      await runAsyncFlow(commit, {
        request: addUnavailability,
        params: [unavailability],
      });
    },
    async updateUnavailability({ commit }, { uuid, unavailability }) {
      await runAsyncFlow(commit, {
        request: updateUnavailability,
        params: [uuid, unavailability],
      });
    },
    async deleteUnavailability({ commit }, uuid) {
      await runAsyncFlow(commit, {
        request: deleteUnavailability,
        params: [uuid],
      });
    },
  },
}));

import { ReportsModule } from '../Reports/store/ReportsModule';
import { ReportsGroupModule } from '../Reports/store/ReportsGroupModule';

export default {
  namespaced: true,
  modules: {
    ReportsModule,
    ReportsGroupModule,
  },
};

import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';

const {
  getVehicleFuelTypes,
  postCreateFuelPrice,
} = backOffice.vehicleFuelPrices;

export const scopes = {
  priceUpdate: 'priceUpdate',
  types: 'types',
};

const fuelPriceModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    clearFuelPriceError(state) {
      state[scopes.priceUpdate].STATUS.ERROR = false;
    },
  },
  actions: {
    async getFuelTypes({ commit }) {
      await runAsyncFlow(commit, {
        request: getVehicleFuelTypes,
        scope: scopes.types,
      });
    },
    async postCreateFuelPrice({ commit }, { request }) {
      await runAsyncFlow(commit, {
        request: postCreateFuelPrice,
        params: [request],
        scope: scopes.priceUpdate,
      });
    },
  },
})));

export default fuelPriceModel;

import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';

const { fleetInvoicing } = backOffice;

export const scopes = {
  invoiceCompiled: 'invoiceCompiled',
  invoice: 'invoice',
};

const invoicesModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  actions: {
    getCompiledInvoice({ commit }, invoiceUuid) {
      runAsyncFlow(commit, {
        request: fleetInvoicing.getCompiledInvoice,
        params: [invoiceUuid],
        scope: scopes.invoiceCompiled,
      });
    },
    getInvoice({ commit }, invoiceUuid) {
      runAsyncFlow(commit, {
        request: fleetInvoicing.getInvoice,
        params: [invoiceUuid],
        scope: scopes.invoice,
      });
    },
    sendInvoiceForReview({ commit }, { invoiceUuid, data }) {
      runAsyncFlow(commit, {
        request: fleetInvoicing.putSendInvoiceForReview,
        params: [invoiceUuid, data],
        scope: scopes.invoice,
      });
    },
    issueInvoice({ commit }, invoiceUuid) {
      runAsyncFlow(commit, {
        request: fleetInvoicing.putIssueInvoice,
        params: [invoiceUuid],
        scope: scopes.invoice,
      });
    },
    rejectInvoice({ commit }, invoiceUuid) {
      runAsyncFlow(commit, {
        request: fleetInvoicing.putRejectInvoice,
        params: [invoiceUuid],
        scope: scopes.invoice,
      });
    },
    editProforma({ commit }, { invoiceUuid, editProforma }) {
      runAsyncFlow(commit, {
        request: fleetInvoicing.putEditProforma,
        params: [invoiceUuid, editProforma],
        scope: scopes.invoice,
      });
    },
  },
}));
export default invoicesModel;
